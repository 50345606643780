<template>
  <div class="pageContol listWrap templateList formCom category1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
            <em>当前位置：</em>
            <a href="javascript:;">题库设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem ci-full">
              <span class="itemLabel">工种类别:</span>
              <treeselect @getValue="getValue($event)" :value="valueId" :props="props" :options="categoryOptionData" :clearable="isClearable"
                          :accordion="isAccordion"/>
            </div>
            <div class="searchboxItem ci-full">
              <span class="itemLabel">是否显示:</span>
              <el-select clearable v-model="searchIsShow" placeholder="请选择">
                <el-option v-for="item in showList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="searchboxItem ci-full">
              <el-button class="bgc-bv" @click="getData(-1)">查询</el-button>
            </div>
          </div>
          <div class="btnBox">
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table :data="tableData" ref="refTable" :header-cell-style="tableHeader" height="100%" size="small" style="width: 100%"
                      row-key="categorySettingId">
              <el-table-column prop="categoryName" label="工种名称" align="left" width="400px"></el-table-column>
              <el-table-column prop="categorySettingId" label="设置ID" align="left" hidden></el-table-column>
              <el-table-column prop="categoryCode" label="工种码值" align="left" hidden></el-table-column>
              <el-table-column label="显示/隐藏" align="center">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.showed" :disabled="!scope.row.hasOwnProperty('categorySettingId')" active-color="#ff4949" inactive-color="#c0ccda" @change="categorySettingChangehidden(scope.row)"/>
                  <span style="margin-left: 5px">{{ !scope.row.showed ? '隐藏' : '显示' }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="questionCount" label="试题数量" align="right"></el-table-column>
              <el-table-column prop="priceIos" label="IOS价格" align="right"></el-table-column>
              <el-table-column prop="priceAndroid" label="安卓价格" align="right"></el-table-column>
              <el-table-column prop="priceMiniapp" label="小程序价格" align="right"></el-table-column>
              <el-table-column prop="validNum" label="有效期(天)" align="right"></el-table-column>
              <el-table-column prop="showIcon" label="显示图标" align="center"></el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <div slot-scope="scope" class="">
                  <el-button size="mini" type="text" @click="goCategorySettingPage(scope.row)">编辑</el-button>
                  <el-button size="mini" type="text" @click="opencategorySettingDialog(scope.row)">智能组卷设置</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData"/>
      </div>
    </div>
    <el-dialog :title="categorySettingDialogTitle" :visible.sync="categorySettingDialogVisible" width="50%" center>
      <el-form ref="categorySettingDialogFromRef" :model="categorySettingDialogFrom" :rules="categorySettingDialogFromRules" label-width="120px">
        <el-form-item label="题库设置ID" prop="categorySettingId" hidden>
          <el-input v-model="categorySettingDialogFrom.categorySettingId" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="题型设置：" class="table_box" prop="tableData">
          <el-table :data="mockQuestionSettingData" height="300" size="small" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
            <el-table-column prop="questionType" label="题型" align="center">
              <template slot-scope="scope">
                <span>{{ getType(scope.row.questionType) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="questionNum" label="数量" align="center" >
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.questionNum" style="width:100%;hight:100%" @input="getState(scope.$index)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="questionScore" label="每题分值" align="center">
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.questionScore" style="width:100%;hight:100%" @input="getState(scope.$index)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="questionTypeNum" label="试题数量" align="center"></el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="总分" prop="mockScoreTotal">
          <el-input v-model="categorySettingDialogFrom.mockScoreTotal" readonly></el-input>
        </el-form-item>
        <el-form-item label="通过分数" prop="mockScorePass">
          <el-input v-model.number="categorySettingDialogFrom.mockScorePass" ></el-input>
        </el-form-item>
        <el-form-item label="时间限制" prop="mockLimitTime">
          <el-input v-model.number="categorySettingDialogFrom.mockLimitTime" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="categorySettingDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="categorySettingEdit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import treeselect from "@/components/treeselect.vue";

export default {
  name: "mini/categorySetting",
  components: {
    treeselect,
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      isClearable: true,      // 可清空（可选）
      isAccordion: true,      // 可收起（可选）
      valueId: "",
      props: {
        value: 'categoryCode',
        label: 'categoryName',
        children: 'children',
          // disabled:true
      },
      categoryOptionData: [],
      searchCategoryCode: "",
      searchIsShow: "",
      showList: [
        {
          label: "显示",
          value: true,
        },
        {
          label: "隐藏",
          value: false,
        },
      ],
      categorySettingData: [],

      categorySettingDialogTitle: "智能组卷设置",
      categorySettingDialogVisible: false,
      categorySettingDialogFrom: {
        categorySettingId: "", //ID
        mockScoreTotal: "", //总分
        mockScorePass: "", //通过分数
        mockLimitTime: "", //时间限制
        mockQuestionSetting: "" //题型设置
      },
      categorySettingDialogFromRules: {
        mockScoreTotal: [
          {required: true, message: "请输入总分", trigger: "blur",},
          {type: "number", message: "总分必须为数字"},
        ],
        mockScorePass: [
          {required: true, message: "请输入通过分数", trigger: "blur",},
          {type: "number", message: "通过分数必须为数字"},
          {validator: this.validatePass, trigger: "blur"},
        ],
        mockLimitTime: [
          {required: true, message: "请输入有效期", trigger: "blur",},
          {type: "number", message: "有效期必须为数字"},
        ],
      },
      mockQuestionSettingData:[],

    };
  },
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
    //计算只能组卷的总分
    mockQuestionSettingData: function() {
      let add = 0;
      this.mockQuestionSettingData.forEach(item => {
        add += item.questionNum * item.questionScore;
      });
      this.categorySettingDialogFrom.mockScoreTotal = add;
    }
  },
  created() {
    this.getCategoryTree();
  },
  computed: {},
  methods: {
    getValue(value) {
      this.searchCategoryCode = value;
    },
    validatePass(rule, value, callback) {
      if (parseInt(value) > this.categorySettingDialogFrom.mockScoreTotal) {
        callback(new Error("通过分数不能大于总分数"));
      } else {
        callback();
      }
    },
    //获取分类树下拉框
    getCategoryTree() {
      let params = {
        disabledLevels: "0"
      }
      this.$post("/miniapp/category/tree", params, 3000, true, 2)
          .then((res) => {
            if (res.status == 0) {
              this.categoryOptionData = res.data;
              return;
            }
            this.$message({
              type: "error",
              message: res.message ? res.message : "操作失败",
            });
          })
          .catch(() => {
            return;
          });
    },
    //获取分类
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        categoryCode: this.searchCategoryCode,
        showed: this.searchIsShow
      }
      this.doFetch(
          {
            url: "/miniapp/categorysetting/pageList",
            params,
            pageNum,
          },
          true,
          2
      );
    },
    // 打开智能组卷弹窗
    opencategorySettingDialog(row) {
      if (this.$refs["categorySettingDialogFromRef"] !== undefined) {
        this.$refs["categorySettingDialogFromRef"].resetFields();
      }
      this.categorySettingDialogVisible = true;
      this.categorySettingDialogFrom = row;
      this.$post(
          "/miniapp/miniquestion/groupbytype",
          row,
          3000,
          true,
          2
      ).then((res) => {
        if (res.status == 0) {
          this.mockQuestionSettingData = res.data
          const setting = eval(row.mockQuestionSetting)
          this.mockQuestionSettingData.forEach(element => {
            setting.forEach(s => {
              if (s.questionType === element.questionType) {
                element.questionNum = s.questionNum
                element.questionScore = s.questionScore
              }
            })
          });
        } else {
          this.$message({
            type: "error",
            message: res.message ? res.message : "操作失败",
          });
        }
      }).catch(() => {
        return;
      });
    },
    // 智能组卷弹窗保存
    categorySettingEdit() {
      this.$refs["categorySettingDialogFromRef"].validate((valid) => {
        this.categorySettingDialogFrom.mockQuestionSetting = JSON.stringify(this.mockQuestionSettingData)
        if (this.categorySettingDialogFrom.mockScoreTotal < this.categorySettingDialogFrom.mockScorePass) {
          this.$message({
            type: "error",
            message: "通过分数不能超过总分数",
          });
          return;
        }
        if (valid) {
          this.$post(
              "/miniapp/categorysetting/saveOrUpdate",
              this.categorySettingDialogFrom,
              3000,
              true,
              2
          ).then((res) => {
            this.$message({
              type: res.status == "0" ? "success" : "error",
              message: res.message ? res.message : "操作失败",
            });
            if (res.status == 0) {
              this.$refs[
                  "categorySettingDialogFromRef"
                  ].resetFields();
              this.categorySettingDialogVisible = false;
              this.getData();
            }
          }).catch(() => {
            return;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 打开题库设置页面
    goCategorySettingPage(row) {
      this.$router.push({
        path: "/web/miniApp/categorySettingEdit",
        query: {
          categorySetting: row,
        },
      });
    },
    //修改分类显示状态
    categorySettingChangehidden(info) {
      if (!info.categorySettingId) {
        this.$message({
          type: "error",
          message: "该工种从未进行过题库设置，请先设置",
        });
        return;
      }
      const params = {
        categorySettingId: info.categorySettingId,
        showed: info.showed,
      };
      this.$post(
          "/miniapp/categorysetting/changeHidden",
          params,
          3000,
          true,
          2
      ).then((res) => {
        this.$message({
          type: res.status == 0 ? "success" : "error",
          message: res.message ? res.message : "操作失败",
        });
      }).catch(() => {
        return;
      });
    },

    getType(type) {
      let typeJson = [
        { value: "0", label: "单选题" },
        { value: "2", label: "判断题" },
        { value: "1", label: "多选题" },
        { value: "3", label: "填空题" },
      ];
      for (let i in typeJson) {
        if (typeJson[i].value == type) {
          return typeJson[i].label;
        }
      }
    },
    getState(index) {
      let questionNum = this.mockQuestionSettingData[index].questionNum;
      let questionTypeNum = this.mockQuestionSettingData[index].questionTypeNum;
      if (questionNum > questionTypeNum) {
        this.$message({
          type: "error",
          message: "题目数量不能超过试题数量",
        });
        this.mockQuestionSettingData[index].questionNum = this.mockQuestionSettingData[index].questionTypeNum || 0;
        return;
      } else {
        this.mockQuestionSettingData[index].questionNum = this.mockQuestionSettingData[index].questionNum || 0;
        this.mockQuestionSettingData[index].questionScore = this.mockQuestionSettingData[index].questionScore || 0;
      }
      this.$set(this.mockQuestionSettingData, index, this.mockQuestionSettingData[index]);
    },
  },
};
</script>
<style lang="less">
// .category1 {
//     .el-switch.is-checked .el-switch__core {
//         background-color: #c0ccda !important;
//         border-color: #c0ccda !important;
//     }
// }
</style>
